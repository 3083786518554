.service_client{
    display: flex;
    height: 100px;
    align-items: center;
    
}
.product_section {
    background-color: rgb(209, 227, 255);
}
.service_box{
    width: 100%;
}
.service_box .box{
    
    min-height: 250px;
    border-radius: 5px;
    margin-top: 0px;
    margin-bottom: 40px;
}

.margin_service{
    margin-bottom: 70px;
}

.service_content .client_section .owl-carousel .owl-nav button{
    display: none;
}

@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    
}
@media (max-width: 600px) {

}
@media (max-width: 400px) {
    
}
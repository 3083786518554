
/*** Spinner ***/
#spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}
.navbar-toggler img {
width: 100%;
}
.navbar-toggler:focus{
    box-shadow: none;
}


.sticky-top {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
    background: #0c1144;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.navbar-brand img {
    width: 120px;
}
.custom_nav-container .navbar-nav .nav-item .nav-link{
    font-weight: 600;
    text-transform: capitalize;
}
.header_section .header_top .contact_nav .social-box a{
    margin: 5px;
}
.top_header a:hover span{
    color: #fec401;
    transition: all 0.3s;
}
.dropdown-item:focus, .dropdown-item:hover{
    color: #fff;
    background-color: #0c1144;
    transition: all 0.3s;
}

.message_content {
  position: fixed;
  bottom: 71px;
  border-radius: 5px;
  right: 120px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  width: 300px;
  z-index: 9999;
}


.popup-content {
  text-align: center;
}

.close-button {
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}


.login_form .card {
    width: 60%;
    margin: 50px auto;
    background-color: #fff; /* Warna card */
    padding: 20px;
    border-radius: 5px; /* Border radius card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login_form h2 {
    text-align: center;
    color: #333;
    text-transform: uppercase;
    font-weight: bold;
    color: #0a0f43;
  }
  
  .login_form form {
    display: flex;
    flex-direction: column;
  }
  
  .login_form input {
    width: 100%;
    border: none;
    height: 50px;
    padding-left: 25px;
    background-color: #ffffff;
    outline: none;
    color: #0a0f43;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  }
  
  .login_form input:focus {
    border-color: #ff9900; /* Warna input saat focus */
  }
  
  .login_form button{
    display: inline-block;
    font-family: 'Merriweather Sans', sans-serif;
    text-transform: uppercase;
    padding: 10px 55px;
    background-color: #0a0f43;
    border: 1px solid #0a0f43;
    border-radius: 5px;
    color: #ffffff;
    margin-top: 15px;
  }

  .login_form button:hover{
    background-color: transparent;
    color: #0a0f43;
  }
  @media (max-width: 992px) {
    
  }
  @media (max-width: 768px) {
    .login_form .card{
      width: 80%;
    }
  }
  @media (max-width: 600px) {
  
  }
  @media (max-width: 400px) {
    .login_form .card{
      width: 100%;
    }
  }
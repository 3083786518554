.manage_user h2 {
    color: #0a0f43;
    margin-bottom: 10px;
}
table {
    width: 100%;
    height: 100%;
    max-height: 300px;
    border-collapse: collapse;
    display: block;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;
}
.table>thead{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: #0a0f43;
    color: #fff;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    min-width: 150px;
}

th {
    background-color: #f2f2f2;
}

.admin_panel {
  width: 100%;
  height: 100%;
  /* max-height: 368px; */
}
  
  .dashboard {
    width: 100%;
    flex: 1;
    padding: 20px 10px 20px 10px;
  }
  
  @media (max-width: 992px) {
    
  }
  @media (max-width: 768px) {
      
  }
  @media (max-width: 600px) {
  
  }
  @media (max-width: 400px) {
      
  }
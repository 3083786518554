.dashboard_section .heading {
    color: #0a0f43;
    margin-bottom: 10px;
}
.dashboard_section .box {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 8px 3px;
}
.dashboard_count {
    width: 100%;
}

.dashboard_count h1 {
    color: #0a0f43;
    font-weight: bold;
    margin: 0px;
}

.dashboard_count h4 {
    color: #fcc72d;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 1px;
}
@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    
}
@media (max-width: 600px) {

}
@media (max-width: 400px) {
    
}
.show_project h2 {
    text-transform: uppercase;
    font-weight: bold;
    color: #0a0f43;
}

.show_project p {
    margin-bottom: 10px;
    padding-left: 26px;
    display: flex;
    flex-wrap: wrap;
}
textarea.message-box {
    width: 100%;
    height: 120px;
    border: none;
    margin-top: 25px;
    padding: 20px 25px;
    background-color: #ffffff;
    outline: none;
    color: #0a0f43;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
}
.get_section{
    background-color: #0a0f43;
    color: #fff;
}
.get_section h6,.get_section  p{
    color: #fff;
}

iframe {
    width: 100%;
}
@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    
}
@media (max-width: 600px) {
    .info_items::before{
        display: none;
    }
}
@media (max-width: 400px) {
    
}
.manage_project h2 {
    color: #0a0f43;
    margin-bottom: 10px;
}
table {
    width: 100%;
    height: 100%;
    max-height: 300px;
    border-collapse: collapse;
    display: block;
    overflow-x: auto;
    position: relative;
    white-space: nowrap;
}
.table>thead{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background-color: #0a0f43;
    color: #fff;
}
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    min-width: 200px;
}

th {
    background-color: #f2f2f2;
}
td{
    white-space: wrap;
}
.button{
    border: none;
    display: inline-block;
    font-family: 'Merriweather Sans', sans-serif;
    text-transform: uppercase;
    padding: 10px 15px;
    background-color: #fec401;
    border: 1px solid #fec401;
    border-radius: 5px;
    color: #ffffff;
}
.button:hover{
    background-color: transparent;
    color: #fec401;
    transition: all 0.3s;
}
.button1{
    border: none;
    display: inline-block;
    font-family: 'Merriweather Sans', sans-serif;
    text-transform: uppercase;
    padding: 10px 55px;
    background-color: #0a0f43;
    border: 1px solid #0a0f43;
    border-radius: 5px;
    color: #ffffff;
    margin-top: 15px;
}
.button1:hover{
    background-color: transparent;
    color: #0a0f43;
    transition: all 0.3s;
}
.manage_project p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin: 10px;
}
.slider_section{
    width: 100%;
    height: 500px;
    position: relative;
}
.slider_section .detail-box {
    padding: 0px 0px;
}

.carousel-item{
    height: 100%;
    max-height: 500px;
}
.carousel-item img{
    height: 100%;
    min-height: 500px;
}

.carousel-caption {
    bottom: 8.25rem;
    right: 0px;
    left: 0px;
}
/* .carousel-item:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0c114481;
    top: 0;
    left: 0;
} */
.carousel-indicators .active{

    background-color: #fec401;
}
.detail-box i.fa.fa-check {
    font-size: 16px;
    color: #fec401;
    padding-right: 5px;
}
.profile_pic {
    width: 100%;
    text-align: center;
}
.profile_pic img{
    width: 75%;
    border-radius: 5px;
}
#background-video {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .overlay {
    background: #0c114454;
    position: absolute;
    width: 100%;
    height: 500px;
    top: 0px;
    left: 0px;
}

.founder_pic img {
    width: 150px;
    height: 150px;
    background-color: #0c1144;
    object-fit: cover;
    object-position: top;
    border-radius: 150px;
    margin: 15px 0px;
}

.founder_para{
    margin: 0 auto;
    width: 71%;
}
.service_para{
    margin: 0 auto;
    width: 80%;
}
@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    .service_section .box{
        min-height: auto;
    }
    .slider_section .detail-box p{
        width: 100%;
    }
    .heading_slider p{
        width: 100%;
    }
}
@media (max-width: 600px) {

}
@media (max-width: 400px) {
    
}
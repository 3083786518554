.footer_items li{
    margin-bottom: 10px;
}


.footer_items ul li a i{
    color: #fff;
    transition: all 0.3s;
}
.footer_items ul li a {
    color: #fff;
    transition: all 0.3s;
    text-decoration: none;
}
.footer_items ul li:hover a {
    color: #fec401;
    transition: all 0.3s;
    padding-left: 2px;
}
.footer_items ul li:hover i {
    color: #fec401;
    transition: all 0.3s;
}

.social-box .box a:hover i{
    color: #fec401;
    transition: all 0.3s;
}
.footer-border p{
    border-top: 0.1px solid;
}
@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    
}
.project_img img{
    height: 250px;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
}
.heading_slider p{
    width: 60%;
    margin: 5px auto 25px auto;
}
@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    .heading_slider p{
        width: 90%;
    }
}
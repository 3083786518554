
.popup {
    position: fixed;
    bottom: 0;
    right: 40px;
    z-index: 9999;
}
.popup {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 9999;
}

.popup_btn button {
    border: 0px;
    display: flex;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    color: #fff;
    font-size: 30px;
    background-color: #fcc72d;
    margin-bottom: 12px;
    justify-content: center;
    align-items: center;
}

.message_btn::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 2px solid #fcc72d;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1s infinite;
    background-color: #fcc72d;
  }
  
  @keyframes ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 150px;
      height: 150px;
      opacity: 0;
    }
  }
  
.service_client{
    display: flex;
    height: 100px;
    align-items: center;
    
}
.product_section{
    text-align: center;
    width: 100%;
    background: url(../../../public/assets/images/Product.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
    align-items: center;
    z-index: 0;
    height: 400px;
}
.product_section h2{
    color: #fff!important;
}
.product_section .overlay {
    width: 100%;
    height: 100%;
    background-color:#131b449c;
    position: absolute;
    top: 0px;
    z-index: -1;
}
.product_section {
    background-color: rgb(209, 227, 255);
}
.service_box{
    width: 100%;
}
.service_box .box{
    
    min-height: 250px;
    border-radius: 5px;
    margin-top: 0px;
    margin-bottom: 40px;
}



.service_content .client_section .owl-carousel .owl-nav button{
    display: none;
}
.service_work{
    width: 100%;
    background: url(../../../public/assets/images/goal.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    position: relative;
    align-items: center;
    z-index: 0;
}
.service_work .overlay{
    width: 100%;
    height: 100%;
    background-color: #0a0f43c3;
    position: absolute;
    top: 0px;
    z-index: -1;
}
.service_work .nav{
    border:none;
    justify-content: center;
}
.service_work .nav-tabs .nav-link:focus, .service_work .nav-tabs .nav-item.show .nav-link, .service_work .nav-tabs .nav-link.active{
    color: #fec401;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 2px solid #fec401;
}
.service_work .nav-tabs .nav-link:hover{
    color: #fec401;
    background-color: transparent;
    border-color: #fff;
    border-bottom: 2px solid #fec401;
}
.service_work .nav-tabs .nav-link {
    margin: 0px 15px;
    background: transparent;
    color: #fff;
    font-weight: bold;
    border: none;
    border-bottom: 2px solid #fff;
}
.content_residental h3 {
    font-weight: bold;
    font-size: 22px;
    color: #fff;
}

.content_residental p {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}
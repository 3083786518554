.contact_content {
  position: fixed;
  bottom: 0px;
  border-radius: 5px;
  right: 110px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  width: 500px;
  height: 100%;
  z-index: 9999;
  overflow: scroll;
}
.send_btn{
  display: inline-block;
    font-family: 'Merriweather Sans', sans-serif;
    text-transform: uppercase;
    padding: 10px 45px;
    background-color: #0a0f43;
    border: 1px solid #0a0f43;
    border-radius: 5px;
    color: #ffffff!important;
}

.popup-content {
  text-align: left;
}

.close-button {
  background-color: #ccc;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.counter_section {
    background: url(../../../public/assets/images/counter.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 400px;
    padding: 50px 0px;
    display: flex;
    align-items: center;
}
.counter_section .overlay{
    width: 100%;
    height: 100%;
    background-color:#0a0f4390;
    position: absolute;
    top :0px;
    z-index: 1;


}
.counter_section .project_count h1,h4{
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}
.counter_content {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: center;
    flex-wrap: wrap;
}
.project_count {
    text-align: center;
    margin: 30px 20px;
    width: 210px;
}

.founder_profile .profile {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.founder_profile .profile img {
    width: 150px;
    height: auto;
    background-color: #0c1144;
    max-height: 150px;
    border-radius: 150px;
    object-fit: cover;
    object-position: top;
}
.founder_profile h2{
    text-transform: uppercase;
    font-weight: bold;
    color: #0a0f43;
}
.box_profile {
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.15);
    padding: 25px 15px;
    transition: all .3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: 450px;
    border-radius: 5px;
    margin-top: 40px;
}
.founder_profile .founder-p{
    width: 100%;
    height: 100%;
    
}
.about_we_work .box{
    min-height: 200px;
}
.about_pic img{
    width: 100%;
    border-radius: 5px;
    height: 370px;
    object-fit: fill;
}

@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    .about_pic {
        margin: 20px 0px;
    }

}
@media (max-width: 600px) {
    .project_count{
        margin: 5px;
    }

}
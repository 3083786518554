
.get_section{
    background-color: #0a0f43;
    color: #fff;
}
.get_section h6,.get_section  p{
    color: #fff;
}
.password{
    position: relative;
}
.eye_icon {
    position: absolute;
    top: 35px;
    right: 20px;
}


.sidebar {
  width: 100%;
  height: 100%;
  /* max-height: 368px; */
  background-color: #0a0f43;
  padding: 25px;
  }
  
  .sidebar h2 {
    margin-bottom: 20px;
    color: #fff;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar li {
    padding: 10px 10px;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
  
  .sidebar li:hover,.sidebar li:focus {
    background-color: #fec401;
    color: #fff;
  }
  .sidebar_menu{
    display: block;
  }
  
  @media (max-width: 992px) {
    .sidebar_menu{
      display: none;
    }
    .sidebar{
      min-height: auto; 
      background-color: #fff;
      padding: 10px;   
    }
    .sidebar h2{
      color: #0a0f43;
      margin: 0px;
    }
    .sidebar li{
      color: #0a0f43;
    }
    .sideclick img {
      width: 40px;
  }
  .sideclick:focus {
    background: #fec401;
    outline: 0px;
    border-radius: 5px;
}
  .sideclick {
      border: none;
      background: transparent;
  }
  
  }
  @media (max-width: 768px) {
      
  }
  @media (max-width: 600px) {
  
  }
  @media (max-width: 400px) {
      
  }

.project_gallery .card {
	position: relative;
	width: 100%;
	background: radial-gradient(#111 50%, #000 100%);
	overflow: hidden;
	cursor: pointer;
}

.project_gallery img {
	max-width: 100%;
    display: block;
    height: 210px;
    object-fit: fill;
}

.project_gallery .card img {
	transform: scale(1.3);
	transition: 0.3s ease-out;
}

.project_gallery .card:hover img {
	transform: scale(1.1) translateY(-20px);
	opacity: 0.3;
}

.project_gallery .overlay {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	padding: 15px;
	text-align: center;
	color: #fff;
}

.project_gallery .text-h1 {
	transform: translateY(30px);
	opacity: 0;
    font-size: 35px;
	transition: all .3s ease-out;
}

.project_gallery .text-p {
	transform: translateY(30px);
    opacity: 0;
    transition: all .3s ease-out 0.2s;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.project_gallery .link-a {
	display: inline-block;
	background: #fec401;
	color: #fff;
	padding: 5px 20px;
	border-radius: 5px;
	transform: translateY(30px);
	opacity: 0;
	transition: all .3s ease-out 0.4s;
}

.project_gallery .overlay .link-a:hover {
	background: #0a0f43;
}

.project_gallery .card:hover .overlay .text-h1 {
	opacity: 1;
	transform: translateY(0);
}

.project_gallery .card:hover .overlay .text-p {
	opacity: 1;
	transform: translateY(0);
}

.project_gallery .card:hover .overlay .link-a {
	opacity: 1;
	transform: translateY(0);
}
.gallery_heading{
    width: 100%;
    text-align: center;
}
.gallery_heading h2{
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    color: #0a0f43;
    justify-content: center;
    font-family: 'Merriweather Sans', sans-serif;
}
.gallery_heading p{
    font-size: 16px;
    font-weight: 500;
    width: 70%;
    margin: 0 auto;
}

*{
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none;
}
ul{
    margin: 0;
    padding: 0;
}
li{
    list-style-type: none;
}